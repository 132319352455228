import React from 'react';
import { FaEnvelope } from "react-icons/fa";
import { FaPhone } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";


function AboutUs () {
  return (
    <>    <div class="container main-div contact-us">
<div class="row">
    <div class="col-lg-12 col-md-12 mb-4">
		<div className='bg-light p-4'>
	<div class="head-title">
			    <h2>About Us</h2>	
			</div>
			
            <p>Welcome to Printing Brother, your trusted partner in corporate gifting and printing solutions. With a commitment to excellence and innovation, we specialize in providing high-quality custom products that elevate your brand and make a lasting impression.</p>
			
            <p>Founded on the principles of quality, reliability, and customer satisfaction, Printing Brother offers a comprehensive range of products including custom t-shirts, sweatshirts, hoodies, caps, lanyards, pens, coasters, keychains, bottles, mugs, bags, and diaries. Our team is dedicated to delivering exceptional service and personalized solutions to meet your unique needs.</p>

            <p>At Printing Brother, we use state-of-the-art technology and premium materials to ensure that every product reflects your brand’s identity with precision and style. Whether you’re looking for promotional items, corporate gifts, or customized apparel, we are here to help you create memorable and impactful branding experiences.</p>

            <p>Explore our diverse product offerings and discover how Printing Brother can enhance your brand’s visibility and reputation. Contact us today to learn more about our services and let us assist you in achieving your marketing goals.</p>


</div>
	</div>
	
	
</div>
</div>
</>
  )
}

export default AboutUs