const Capapi = [
    {
      "image": "https://printingbrother.com/images/int-cat-images/cap1_pb.jpg",
      "id": 1,
      "url": "./component/Internal_Pages/Tshirt",
      "title": "T-Shirts",
      "alt": "T-Shirt",
    },
    {
      "image": "https://printingbrother.com/images/int-cat-images/cap2_pb.jpg",
      "id": 2,
      "url": "./component/Internal_Pages/Hoodies",
      "title": "Sweatshirts & Hoodies",
      "alt": "Sweatshirts & Hoodies",
    },
    {
      "image": "https://printingbrother.com/images/int-cat-images/cap3_pb.jpg",
      "id": 3,
      "url": "./component/Internal_Pages/Caps",
      "title": "Caps ",
      "alt": "Caps ",
    },
    {
      "image": "https://printingbrother.com/images/int-cat-images/cap4_pb.jpg",
      "id": 4,
      "url": "./component/Internal_Pages/Lanyards",
      "title": "Lanyards",
      "alt": "Lanyards",
    }
  ];
 export default Capapi;