import React from 'react'
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import logo from '../pb_logo.png';
import { BsLinkedin, BsEnvelope, BsFacebook, BsInstagram} from "react-icons/bs";
import { FaPhone } from "react-icons/fa6";

const Footer = () => {
  return (
    <>
        <section className='footer'>
            <div class="container">
        <div class="footer-top">
            <div class="row">
                <div class="col-md-6 col-lg-4 about-footer">
                    <h3><a href="/" class="main_logo"><img src="/static/media/pb_logo.f5a41295ae38f5cb0877.png" alt="img" /></a></h3>
                    <ul className='list-unstyled'>
                        <li><a href="tel:9717793856"><FaPhone /> (+91) 9717793856</a></li>
                        <li><a href="tel:8285338467"><FaPhone /> (+91) 8285338467</a></li>
                        <li><a href="mailto:printingbrothers@gmail.com"><BsEnvelope /> printingbrothers2023@gmail.com</a></li>
                    </ul>
                </div>
                <div class="col-md-6 col-lg-2 page-more-info">
                    <div class="footer-title">
                        <h4>Quick links</h4>
                    </div>
                    <ul className='list-unstyled'>
                       <li><a href="/">Home</a></li>
                       <li><a href="/aboutus">About</a></li>
                       <li><a href="/">Blog</a></li>
                       <li><a href="/contactus">Contact</a></li>
                    </ul>
                </div>

                <div class="col-md-6 col-lg-2 page-more-info">
                    <div class="footer-title">
                        <h4>More Info</h4>
                    </div>
                    <ul className='list-unstyled'>
                        <li><a href="/component/Internal_Pages/Tshirt">T-Shirts</a></li>
                        <li><a href="component/Internal_Pages/Lanyards">Lanyards & Badges</a></li>
                        <li><a href="/component/Internal_Pages/Caps">Caps</a></li>
                        <li><a href="/component/Internal_Pages/Hoodies">Sweatshirts & Hoodies</a></li>
                    </ul>
                </div>
                <div class="col-md-6 col-lg-4 open-hours">
                    <div class="footer-title social_profile">
                        <h4>Open hours</h4>
                        <ul class="footer-social">
                        <li>
                            <a href="https://www.instagram.com/printing.brothers/" target='_blank'><BsInstagram /></a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/profile.php?id=61551030107126" target='_blank'><BsFacebook /></a>
                        </li>
                        <li>
                        <a href='https://www.linkedin.com/in/printing-brothers-064298292/' target='_blank'><BsLinkedin /></a>
                        </li>

                        </ul>
                    </div>
                    <table class="table">
                        <tbody>
                            <tr>
                                <td><i class="far fa-clock"></i>Monday - Thursday</td>
                                <td>10:00am - 8:00pm</td>
                            </tr>
                            <tr>
                                <td><i class="far fa-clock"></i>Friday</td>
                                <td>10:00am - 8:00pm</td>
                            </tr>
                            <tr>
                                <td><i class="far fa-clock"></i>Sturday</td>
                                <td>10:00am - 8:00pm</td>
                            </tr>
                            <tr>
                                <td><i class="far fa-clock"></i>Sunday</td>
                                <td>Closed</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <hr ></hr>
        <div class="footer-bottom">
            <div class="row">
                <div class="col-sm-4">
                    <a href="">Privacy policy</a>
                </div>
                <div class="col-sm-8">
                <p class="copyright">Copyright © 2024 <a href="#">printingbrothers</a></p>
                </div>
            </div>
        </div>
    </div>
        </section>
    </>
  )
}

export default Footer