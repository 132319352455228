import React, { useState } from 'react'
import PenApi from '../json/penApi';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { Pen } from './allinternal';

const Pens = () => {

const [PenApiData, setPenApiData] = useState(PenApi);
    return (
      <>
       <Container className='main-div'>
            <Row className="">
              <div class="col-lg-12">
                <div className='Paper-products'>
                <div className='row'>
            <div class="col-lg-12 col-md-12 mb-4">
                <div className='bg-light p-4'>
                <div class="main_heading">
                    <h1>Pen</h1>	
                </div>
                <p className=''>Leave a lasting impression with custom pens from Printing Brother. Perfect for corporate gifts, events, and giveaways, our pens can be personalized with your logo or message. Choose from a variety of styles and finishes to match your branding needs.</p>   
                </div>
            </div>
       </div>
                <div class="row">
                  <Pen PenApiData={PenApiData} />
                </div></div>
              </div>
            </Row>
        </Container>
      </>
    )
  }

export default Pens