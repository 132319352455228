const LanyardApi = [
    {
      "image": "http://printingbrother.com/images/int-cat-images/lanyards1_printingbrothers.jpg",
      "id": 1,
      "url": "./component/Internal_Pages/Tshirt",
      "title": "T-Shirts",
      "alt": "T-Shirt",
      "discription": "Discover the perfect gift for any occasion on our gifting website, where we offer a versatile range of T-shirts crafted from high-quality materials including Polyester, Poly cotton, and Cotton. Whether you're seeking comfort, durability, or style, our selection has something for everyone."
    },
    {
      "image": "http://printingbrother.com/images/int-cat-images/lanyards2_printingbrothers.jpg",
      "id": 2,
      "url": "./component/Internal_Pages/Hoodies",
      "title": "Sweatshirts & Hoodies",
      "alt": "Sweatshirts & Hoodies",
      "discription": "est rerum tempore vitae\nsequi sint nihil reprehenderit dolor beatae ea dolores neque\nfugiat blanditiis voluptate porro vel nihil molestiae ut reiciendis\nqui aperiam non debitis possimus qui neque nisi nulla"
    },
    {
      "image": "http://printingbrother.com/images/int-cat-images/lanyards3_printingbrothers.jpg",
      "id": 3,
      "url": "./component/Internal_Pages/Caps",
      "title": "Caps ",
      "alt": "Caps ",
      "discription": "et iusto sed quo iure\nvoluptatem occaecati omnis eligendi aut ad\nvoluptatem doloribus vel accusantium quis pariatur\nmolestiae porro eius odio et labore et velit aut"
    },
    {
      "image": "http://printingbrother.com/images/int-cat-images/lanyards4_printingbrothers.jpg",
      "id": 4,
      "url": "./component/Internal_Pages/Lanyards",
      "title": "Lanyards",
      "alt": "Lanyards",
      "discription": "quia et suscipit\nsuscipit recusandae consequuntur expedita et cum\nreprehenderit molestiae ut ut quas totam\nnostrum rerum est autem sunt rem eveniet architecto"
    }
  ];
 export default LanyardApi;