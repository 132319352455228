import React from 'react'
import Col from 'react-bootstrap/Col';

const Diaries = () => {
  return (
    <>    <div class="container main-div">
      <div className='row'>
      <div class="col-lg-12 col-md-12 mb-4">
		<div className='bg-light p-4'>
			<div class="main_heading">
			    <h1>Diaries</h1>	
			</div>
            <p>Custom Diaries for Professional Branding</p>
            <p className='mb-0'>Enhance your brand’s presence with personalized diaries from Printing Brother. Perfect for corporate gifts and promotional items, our diaries can be customized with your logo or design. Ideal for organizing and planning, they offer a practical yet elegant branding solution.</p>
	</div></div>
      </div>
      <div class="row">
            {/* <div class="col-lg-12 col-md-12">
            <div class="main_heading">
                <h2>Round Neck t-shirts</h2>
            </div>
            </div> */}
        <Col lg="3" md="3" sm="4" xs="6" className='main_box'>
                <div>
                    <div className='image_wraper img-hover-zoom--slowmo border'>
                    <div> 
                        <img src='http://printingbrother.com/images/cs.jpg' alt=''/>
                    </div>
                    </div>
                    <div className='caption'>
                    {/* <h5>Cotton Classic T-shirts</h5> */}
                    {/* <p>{discription}</p> */}
                    {/* <a class="" href={link}>View All</a> */}
                    </div>
                </div>   
            </Col>
            <Col lg="3" md="3" sm="4" xs="6" className='main_box'>
                <div>
                    <div className='image_wraper img-hover-zoom--slowmo border'>
                        <div> 
                        <img src='http://printingbrother.com/images/cs.jpg' alt=''/>
                        </div>
                    </div>
                    <div className='caption'>
                        {/* <h5>Polyester T-Shirt</h5> */}
                        {/* <p>{discription}</p> */}
                        {/* <a class="" href={link}>View All</a> */}
                    </div>
                </div>       
            </Col>
            <Col lg="3" md="3" sm="4" xs="6" className='main_box'>
                <div>
                    <div className='image_wraper img-hover-zoom--slowmo border'>
                        <div> 
                            <img src='http://printingbrother.com/images/cs.jpg' alt=''/>
                        </div>
                    </div>
                    <div className='caption'>
                        {/* <h5>Sarina/Dri-Fit T-shirts</h5> */}
                        {/* <p>{discription}</p> */}
                        {/* <a class="" href={link}>View All</a> */}
                    </div>
                </div>       
            </Col>
            <Col lg="3" md="3" sm="4" xs="6" className='main_box'>
                <div>
                    <div className='image_wraper img-hover-zoom--slowmo border'>
                        <div> 
                            <img src='http://printingbrother.com/images/cs.jpg' alt=''/>
                        </div>
                    </div>
                    <div className='caption'>
                        {/* <h5>Cotton Oversized T-Shirt</h5> */}
                        {/* <p>{discription}</p> */}
                        {/* <a class="" href={link}>View All</a> */}
                    </div>
                </div>       
            </Col>
        </div>
    </div>
    </>
  )
}

export default Diaries