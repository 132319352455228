import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HashRouter as Router, Route, Switch, Routes } from 'react-router-dom';
// import { Switch, Route } from "react-router-dom";
import Contactus from './component/Contactus';
import Home from './component/Home';
// import Tshirt from './component/Internal_Pages/Tshirt';
import HomeNav from './component/HomeNav';
import AboutUs from './component/AboutUs';
import OurGallery from './component/OurGallery';
import Tshirt from './component/Internal_Pages/Tshirt';
import Hoodies from './component/Internal_Pages/Hoodies';
import Caps from './component/Internal_Pages/Caps';
import Pens from './component/Internal_Pages/Pens';
import Mugs from './component/Internal_Pages/Mugs';
import Lanyards from './component/Internal_Pages/Lanyards';
import KeyChains from './component/Internal_Pages/KeyChains';
import Coasters from './component/Internal_Pages/Coasters';
import Drinkware from './component/Internal_Pages/Drinkware';
import Bags from './component/Internal_Pages/Bags';
import Diaries from './component/Internal_Pages/Diaries';

function App() {
  return (
    <Router>
      <>
      <HomeNav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Contactus" element={<Contactus />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/OurGallery" element={<OurGallery />} />
          <Route path='/component/Internal_Pages/Tshirt' element={<Tshirt />} />
          <Route path='/component/Internal_Pages/Hoodies' element={<Hoodies />} />
          <Route path='/component/Internal_Pages/Caps' element={<Caps />} />
          <Route path='/component/Internal_Pages/Pens' element={<Pens />} />
          <Route path='/component/Internal_Pages/Mugs' element={<Mugs />} />
          <Route path='/component/Internal_Pages/Lanyards' element={<Lanyards />} />
          <Route path='/component/Internal_Pages/KeyChains' element={<KeyChains />} />
          <Route path='/component/Internal_Pages/Coasters' element={<Coasters />} />
          <Route path='/component/Internal_Pages/Drinkware' element={<Drinkware />} />
          <Route path='/component/Internal_Pages/Bags' element={<Bags />} />
          <Route path='/component/Internal_Pages/Diaries' element={<Diaries />} />



        </Routes>      
      </>
    </Router>
  );
}

export default App;