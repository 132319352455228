import React, { useState } from 'react'
import Capapi from '../json/capApi';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { Cap } from './allinternal';

const Caps = () => {

const [CapapiData, setCapapiData] = useState(Capapi);
    return (
      <>
       <Container className='main-div'>
            <Row className="">
              <div class="col-lg-12">
                <div className='Paper-products'>
                <div className='row'>
       <div class="col-lg-12 col-md-12 mb-4">
 		<div className='bg-light p-4'>
 			<div class="main_heading">
 			    <h1>Caps</h1>	
 			</div>
             <p className=''>Elevate your brand with custom caps from Printing Brother. Our premium caps are perfect for corporate gifting, promotional events, and team uniforms. Made with top-quality materials, our caps offer comfort, durability, and style. Personalize with your logo, design, or message to make a lasting impression. With a variety of colors and styles to choose from, Printing Brother ensures your brand stands out. Order now for fast delivery and exceptional customer service. Enhance your marketing strategy with our bespoke cap solutions.</p>   
 	</div></div>
       </div>
                <div class="row">
                  <Cap CapapiData={CapapiData} />
                </div>
                </div>
              </div>
            </Row>
        </Container>
      </>
    )
  }

export default Caps