import React from 'react'
import Category from "./Category";
import Why_choose_us from './Why_choose_us';
import Slider from './Slider';
// import Easy_Steps from './Easy_Steps';
import {Helmet} from "react-helmet";



const Home = () => {
  return (
    <>
<Helmet>
<meta name="google-site-verification" content="iM5JzhYBf-KDLiXlQHgMViU7djSoFaDOkfkJTTWViAs" />
<title>Corporate Gifts in Delhi | Printing services in Delhi | Welcome Kit | Corporate gift for your company and client | Printing Brothers</title>
<meta name="description" content="Printing Brothers offers exceptional corporate gifting and printing services. Explore our customizable products and experience outstanding customer support. Visit us for all your printing needs!" />
<meta property="og:title" content="Gifting | Printing in India | Printingbrothers.com" />
<meta property="og:description" content="We specialize in turning your ideas into reality. Whether you need custom t-shirts, jackets, bottles, mugs, or any type of customized gift, we’ve got you covered. Our wide range of printing services ensures that you find exactly what you need to express yourself or make a memorable gift for someone special." />
<meta property="og:image" content="https://printingbrother.com/images/image_1.jpg" />
<link rel="canonical" href="https://printingbrother.com/" />
</Helmet>
        
        <Slider />
        <Why_choose_us />
        {/* <Easy_Steps /> */}
        <Category />
    </>
  )
}

export default Home