import React from 'react'
import Col from 'react-bootstrap/Col';
import {Helmet} from "react-helmet";

const Tshirt = () => {
  return (
    <>  
      <Helmet>
        <meta charSet="utf-8" />
        <title>Custom T-Shirts | T-Shirts Printing in India | Printingbrothers.com</title>
        <meta name="description" content="Custom T-Shirts | T-Shirts Printing in India | Printingbrothers.com" />
        <meta property="og:title" content="Custom T-Shirts | T-Shirts Printing in India | Printingbrothers.com" />
        <meta property="og:description" content="T-shirts are a staple in casual wear, known for their comfort and versatility." />
        <meta property="og:image" content="https://printingbrother.com/images/int-cat-images/polyster_pb.jpg" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="http://printingbrother.com/tshirts" />
      </Helmet>
    
      <div class="container main-div">
      <div className='row'>
      <div class="col-lg-12 col-md-12 mb-4">
		<div className=''>
			<div class="main_heading">
			    <h1>T-SHIRTS</h1>	
			</div>
          <p>Boost your brand’s visibility with custom t-shirts from Printing Brother. Ideal for corporate events, team uniforms, and promotional giveaways, our high-quality t-shirts offer comfort and style. Personalize with your logo or design to make a memorable impact. Explore our range of colors and sizes to fit your needs.</p>
	</div></div>
      </div>
    <div class="row">
        <div class="col-lg-12 col-md-12">
          <div class="main_heading">
              <h4 className=''>Round Neck t-shirts</h4>
          </div>
        </div>
      <Col lg="3" md="3" sm="4" xs="6">
            <div className='main_box'>
                <div className='image_wraper img-hover-zoom--slowmo border'>
                  <div> 
                    <img src='http://printingbrother.com/images/int-cat-images/cotton_pb.jpg' alt=''/>
                  </div>
                </div>
                <div className='caption'>
                  <h5>Cotton Classic T-shirts</h5>
                  {/* <p>{discription}</p> */}
                  {/* <a class="" href={link}>View All</a> */}
                </div>
            </div>   
          </Col>
          <Col lg="3" md="3" sm="4" xs="6">
            <div className='main_box'>
                  <div className='image_wraper img-hover-zoom--slowmo border'>
                    <div> 
                      <img src='http://printingbrother.com/images/int-cat-images/polyster_pb.jpg' alt=''/>
                    </div>
                  </div>
                  <div className='caption'>
                    <h5>Polyester T-Shirt</h5>
                    {/* <p>{discription}</p> */}
                    {/* <a class="" href={link}>View All</a> */}
                  </div>
            </div>       
          </Col>
          <Col lg="3" md="3" sm="4" xs="6">
            <div className='main_box'>
                  <div className='image_wraper img-hover-zoom--slowmo border'>
                    <div> 
                      <img src='http://printingbrother.com/images/int-cat-images/dryfit_pb.jpg' alt=''/>
                    </div>
                  </div>
                  <div className='caption'>
                    <h5>Sarina/Dri-Fit T-shirts</h5>
                    {/* <p>{discription}</p> */}
                    {/* <a class="" href={link}>View All</a> */}
                  </div>
              </div>       
          </Col>
          <Col lg="3" md="3" sm="4" xs="6">
            <div className='main_box'>
                  <div className='image_wraper img-hover-zoom--slowmo border'>
                    <div> 
                      <img src='http://printingbrother.com/images/int-cat-images/oversized_pb.jpg' alt=''/>
                    </div>
                  </div>
                  <div className='caption'>
                    <h5>Oversized T-Shirt</h5>
                    {/* <p>{discription}</p> */}
                    {/* <a class="" href={link}>View All</a> */}
                  </div>
              </div>       
          </Col>
    </div>
    <div class="row">
        <div class="col-lg-12 col-md-12">
      <div class="main_heading">
              <h4 className=''>Polo t-shirts</h4>
          </div>
      </div>
      <Col lg="3" md="3" sm="4" xs="6">
            <div className='main_box'>
                <div className='image_wraper img-hover-zoom--slowmo border'>
                  <div> 
                    <img src='http://printingbrother.com/images/int-cat-images/collar_cotton_pb.jpg' alt=''/>
                  </div>
                </div>
                <div className='caption'>
                  <h5>Collar Cotton T-shirts</h5>
                  {/* <p>{discription}</p> */}
                  {/* <a class="" href={link}>View All</a> */}
                </div>
            </div>   
          </Col>
          <Col lg="3" md="3" sm="4" xs="6">
            <div className='main_box'>
                <div className='image_wraper img-hover-zoom--slowmo border'>
                  <div> 
                    <img src='http://printingbrother.com/images/int-cat-images/collar_dryfit_pb.jpg' alt=''/>
                  </div>
                </div>
                <div className='caption'>
                  <h5>Collar Dri-Fit / Sarina T-shirts</h5>
                  {/* <p>{discription}</p> */}
                  {/* <a class="" href={link}>View All</a> */}
                </div>
            </div>        
          </Col>
    </div>
    </div>
    </>
  )
}

export default Tshirt