import React from 'react'
import Col from 'react-bootstrap/Col';

const Hoodies = () => {
  return (
    <>    <div class="container main-div">
      <div className='row'>
      <div class="col-lg-12 col-md-12 mb-4">
        <div className='bg-light p-4'>
          <div class="main_heading">
              <h1>Sweatshirts & Hoodies</h1>
          </div>
          <p>Personalized Sweatshirts and Hoodies for Every Occasion</p>
          <p className=''>Stay cozy and stylish with custom sweatshirts and hoodies from Printing Brother. Perfect for corporate gifts, team apparel, or promotional events, our sweatshirts and hoodies are crafted from premium materials for durability and comfort. Add your logo or custom design to make a lasting impression.</p>
        </div>
      </div>
      </div>
    <div class="row">
        {/* <div class="col-lg-12 col-md-12">
          <div class="main_heading">
          <h4 className=''>HOODIES</h4>
          </div>
        </div> */}
      <Col lg="3" md="3" sm="4" xs="6" className='main_box'>
            <div>
                <div className='image_wraper img-hover-zoom--slowmo border'>
                  <div> 
                    <img src='https://printingbrother.com/images/int-cat-images/hoodie1_pb.jpg' alt=''/>
                  </div>
                </div>
                <div className='caption'>
                {/* <h5>Cotton Hoodie</h5> */}
                  {/* <p>{discription}</p> */}
                  {/* <a class="" href={link}>View All</a> */}
                </div>
            </div>   
          </Col>
          <Col lg="3" md="3" sm="4" xs="6" className='main_box'>
            <div>
                  <div className='image_wraper img-hover-zoom--slowmo border'>
                    <div> 
                      <img src='http://printingbrother.com/images/int-cat-images/hoodie2_pb.jpg' alt=''/>
                    </div>
                  </div>
                  <div className='caption'>
                    {/* <h5>Polyester T-Shirt</h5> */}
                    {/* <p>{discription}</p> */}
                    {/* <a class="" href={link}>View All</a> */}
                  </div>
            </div>       
          </Col>
          <Col lg="3" md="3" sm="4" xs="6" className='main_box'>
            <div>
                  <div className='image_wraper img-hover-zoom--slowmo border'>
                    <div> 
                      <img src='http://printingbrother.com/images/int-cat-images/hoodie3_pb.jpg' alt=''/>
                    </div>
                  </div>
                  <div className='caption'>
                    {/* <h5>Sarina/Dri-Fit T-shirts</h5> */}
                    {/* <p>{discription}</p> */}
                    {/* <a class="" href={link}>View All</a> */}
                  </div>
              </div>       
          </Col>
          <Col lg="3" md="3" sm="4" xs="6" className='main_box'>
            <div>
                  <div className='image_wraper img-hover-zoom--slowmo border'>
                    <div> 
                      <img src='http://printingbrother.com/images/int-cat-images/hoodie4_pb.jpg' alt=''/>
                    </div>
                  </div>
                  <div className='caption'>
                    {/* <h5>Cotton Oversized T-Shirt</h5> */}
                    {/* <p>{discription}</p> */}
                    {/* <a class="" href={link}>View All</a> */}
                  </div>
              </div>       
          </Col>
    </div>
    </div>
    </>
  )
}

export default Hoodies