import React, { useState } from 'react'
import Menu from './json/mainApi';
import Pmenu from './json/paperApi';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Menucard from './Menucard';
import Papercard from './Papercard'

const Category  = () => {

  const [menuData, setMenuData] = useState(Menu);
  const [pmenuData, setPmenuData] = useState(Pmenu);
  

  return (
    <>
     <Container className='main-div'>
         <Row className="">
            <div class="col-lg-9">
              <div class="row">
              <div class="main_heading d-none"><h2>All Products</h2></div>
                <Menucard menuData={menuData}/>
              </div>
            </div>
            <div className="col-3 d-none d-lg-block">
              <img src="http://printingbrother.com/images/adv/advertisement_1.jpg"></img>
            </div>
            </Row>
            <Row className="">
            <div class="col-lg-12">
              <div class="main_heading"><h2>Paper Products</h2></div>
              <div className='Paper-products'>
              <div class="row">
                <Papercard pmenuData={pmenuData} />
                </div></div>
            </div>
        </Row>
      </Container>
    </>
  )
}

export default Category