import React from 'react'
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';


export const Mugs  = ({ MugApiData }) => {
    return (
        <>
        {MugApiData.map((curElem) => {
          const { id, url, alt, title} = curElem;
            return(
                <>
                    <Col lg="3" md="3" sm="4" xs="4"  id={id}>
                    <div to={curElem.url} className='main_box'>
                            <div className='image_wraper img-hover-zoom--slowmo border'>
                                <div key={curElem.id}>
                                    <img src={curElem.image} alt={alt}/>
                                </div>
                            </div>
                            <div className='caption'>
                                {/* <h5>{title}</h5> */}
                                    {/* <p>{discription}</p> */}
                                    {/* <a class="" href={link}>View All</a> */}
                            </div>
                        </div>        
                    </Col>
                </>
            )
        })}    
        </>
      )
    };


export const Cap  = ({ CapapiData }) => {
    return (
        <>
        {CapapiData.map((curElem) => {
          const { id, url, alt, title} = curElem;
            return(
                <>
                    <Col lg="3" md="3" sm="4" xs="4"  id={id}>
                        <Link to={curElem.url} className='main_box'>
                            <div className='image_wraper img-hover-zoom--slowmo border'>
                                <div key={curElem.id}>
                                    <img src={curElem.image} alt={alt}/>
                                </div>
                            </div>
                            <div className='caption'>
                                {/* <h5>{title}</h5> */}
                                    {/* <p>{discription}</p> */}
                                    {/* <a class="" href={link}>View All</a> */}
                            </div>
                        </Link>        
                    </Col>
                </>
            )
        })}    
        </>
      )
    };


export const Lanyard  = ({ lanyardApiData }) => {
    return (
        <>
        {lanyardApiData.map((curElem) => {
          const { id, url, alt, title} = curElem;
            return(
                <>
                    <Col lg="3" md="3" sm="4" xs="4"  id={id}>
                    <Link to={curElem.url} className='main_box'>
                            <div className='image_wraper img-hover-zoom--slowmo border'>
                                <div key={curElem.id}>
                                    <img src={curElem.image} alt={alt}/>
                                </div>
                            </div>
                            <div className='caption'>
                                <h5>{title}</h5>
                                    {/* <p>{discription}</p> */}
                                    {/* <a class="" href={link}>View All</a> */}
                            </div>
                        </Link>        
                    </Col>
                </>
            )
        })}    
        </>
      )
    };

export const Pen  = ({ PenApiData }) => {
        return (
            <>
            {PenApiData.map((curElem) => {
              const { id, url, alt, title} = curElem;
                return(
                    <>
                        <Col lg="3" md="3" sm="4" xs="4"  id={id}>
                        <div to={curElem.url} className='main_box'>
                                <div className='image_wraper img-hover-zoom--slowmo border'>
                                    <div key={curElem.id}>
                                        <img src={curElem.image} alt={alt}/>
                                    </div>
                                </div>
                                <div className='caption'>
                                    <h5>{title}</h5>
                                        {/* <p>{discription}</p> */}
                                        {/* <a class="" href={link}>View All</a> */}
                                </div>
                            </div>        
                        </Col>
                    </>
                )
            })}    
            </>
          )
        } 