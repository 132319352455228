// src/components/Map.js

import React from 'react';

const Map = () => {
  return (
    <section>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.607553253957!2d77.17822997495153!3d28.491363190478285!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1fbe97b203b3%3A0x64b72d11709f1ffe!2sPrinting%20Brothers!5e0!3m2!1sen!2sin!4v1720265713927!5m2!1sen!2sin"
        width="100%"
        height="250"
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
      ></iframe>
    </section>
  );
};

export default Map;
