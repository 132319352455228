import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
// import { CiDeliveryTruck, CiMedal } from "react-icons/ci";
// import delivery_truck from '../images/icons/delivery_truck.svg'
import { BsCollection } from "react-icons/bs";
import { IoSettingsOutline, IoShieldCheckmarkOutline } from "react-icons/io5";
import { CiDeliveryTruck } from "react-icons/ci";
import { ImGift } from "react-icons/im";
import { GiTakeMyMoney } from "react-icons/gi";


const Why_choose_us = () => {
  return (
    <>
    <section className='why_choose_us'>
    <Container>
        <Row className='justify-content-center g-3'>
            {/* <div class="main_heading text-white"><h2>Why Choose Us ?</h2></div> */}
            <Col xxl="2" xl="4" lg="4" md="4" sm="6" xs="6">
                <Card>
                    <Card.Body>
                    {/* <img src="http://printingbrother.com/images/personalized.png" alt="img"/> */}
                    <BsCollection />
                        <Card.Title>Extensive Collection of Corporate Gifts</Card.Title>
                    </Card.Body>
                </Card>                             
            </Col>
            <Col xxl="2" xl="4" lg="4" md="4" sm="6" xs="6">
                <Card>
                    <Card.Body>
                        {/* <img src="http://printingbrother.com/images/service.png" alt="img"/>*/}
                        <IoSettingsOutline />
                        <Card.Title>Customization On Corporate Gifts</Card.Title>
                    </Card.Body>
                </Card>                             
            </Col>
            <Col xxl="2" xl="4" lg="4" md="4" sm="6" xs="6">
                <Card>
                    <Card.Body>
                        {/* <img src="http://printingbrother.com/images/fast-delivery.png" alt="img"/> */}
                        <IoShieldCheckmarkOutline />
                        <Card.Title>Corporate Gifting Excellence</Card.Title>
                    </Card.Body>
                </Card>                             
            </Col>
            <Col xxl="2" xl="4" lg="4" md="4" sm="6" xs="6">
                <Card>
                    <Card.Body>
                        {/* <img src="http://printingbrother.com/images/reward.png" alt="img"/> */}
                        <CiDeliveryTruck />
                        <Card.Title>Fast Express Doorstep Delivery</Card.Title>
                    </Card.Body>
                </Card>                          
            </Col>
            <Col xxl="2" xl="4" lg="4" md="4" sm="6" xs="6">
                <Card>
                    <Card.Body>
                        {/* <img src="http://printingbrother.com/images/reward.png" alt="img"/> */}
                        <ImGift />
                        <Card.Title>Beautifully Designed Gift Packaging</Card.Title>
                    </Card.Body>
                </Card>                          
            </Col>
            <Col xxl="2" xl="4" lg="4" md="4" sm="6" xs="6">
                <Card>
                    <Card.Body>
                        {/* <img src="http://printingbrother.com/images/reward.png" alt="img"/> */}
                        <GiTakeMyMoney />
                        <Card.Title>Competitive Price</Card.Title>
                    </Card.Body>
                </Card>                          
            </Col>
        </Row>
    </Container>
    </section>
    </>
  )
}

export default Why_choose_us