import React from 'react';
import Map from './Map';
import { FaEnvelope } from "react-icons/fa";
import { FaPhone } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";


function Contactus() {
  return (
    <>    <div class="container main-div contact-us">
<div class="row">
    <div class="col-lg-12 col-md-12 mb-4">
		<div className='bg-light p-4'>
			<div class="head-title">
			    <h2>GET IN TOUCH</h2>	
			</div>
            <p>Have a question, need a quote, or ready to place an order? We're here to help! Get in touch with Printing Brothers today for all your printing needs. Whether you're looking for custom t-shirts, jackets, bottles, mugs, or any other personalized gifts, our team is dedicated to providing you with high-quality products and excellent service. Feel free to reach out to us via phone or email, or visit our location during business hours. We look forward to hearing from you!</p>
		</div>
	</div>
	{/* <div class="col-lg-8 col-md-12 bg-light p-4 mb-4">
			<div class="head-title">
			<h2>GET IN TOUCH</h2>
			</div>
		<form name="contactForm" method="POST" action="contact-form.php" class="form-row">
			<div class="form-group col-sm-6">
			<label for="exampleInputPassword1">Name</label>
			<input type="text" name="name" class="form-control" placeholder="Name" required data-error="your name" />
		  </div>
		  <div class="form-group col-sm-6">
			<label for="exampleInputEmail1">Email address</label>
			<input type="email" class="email form-control" name="email" placeholder="Email" required data-error="Enter your email" />
		  </div>
		  <div class="form-group col-sm-12">
			<label for="exampleInputPassword1">Name</label>
			<input type="text" name="contact" class="form-control" placeholder="Contact no" required data-error="Enter your contact number"/>
		  </div>
		  <div class="form-group col-sm-12">
			<label for="exampleFormControlTextarea1">Location</label>
			<textarea name="message" rows="6" placeholder="Massage" class="form-control" required data-error="Type your message"></textarea>
		  </div>
		  <div class="col-sm-12">
		   <button type="submit" value="submit" class="btn btn-dark quote-btn">Submit</button>
		  </div>
		</form>
	</div> */}
	<div class="col-lg-12 col-md-12">
        <div className="row">
            <div class="col-sm-4 col-12 mb-3">
                <div class="px-3 py-4 text-center border contact-list">
                <FaMapMarkerAlt className='mb-3'/>
                <h5 class="font-weight-bold">VISIT OUR OFFICE</h5>
                <p class="mb-0">B-415, Chhatarpur Extension, Chhatarpur, New Delhi, Delhi 110074</p>
                </div>
            </div>
            <div class="col-sm-4 col-12 mb-3">
                <div class="px-3 py-4 text-center border contact-list">
                <FaEnvelope className='mb-3' />
                <h5 class="font-weight-bold">EMAIL US</h5>
                <p class="mb-0"><a href="mailto:printingbrothers2023@gmail.com" class="">printingbrothers2023@gmail.com</a></p>
                </div>
            </div>
            <div class="col-sm-4 col-12 mb-3">
                <div class="px-3 py-4 text-center border contact-list">
                <FaPhone className='mb-3'/>
                <h5 class="font-weight-bold">CALL US</h5>
                <p class="mb-0">+91 9717793856</p>
                <p class="mb-0">+91 8285338467 </p>
                </div>
            </div>
        </div>
	</div>
</div>
</div>
<Map />
</>


  )
}

export default Contactus