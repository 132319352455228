import React from 'react'
import Col from 'react-bootstrap/Col';

const Drinkware = () => {
  return (
    <>    <div class="container main-div">
      <div className='row'>
      <div class="col-lg-12 col-md-12 mb-4">
		<div className='bg-light p-4'>
			<div class="main_heading">
			    <h1>Drinkware</h1>
			</div>
            <p className='mb-0'>T-shirts are a staple in casual wear, known for their comfort and versatility. They come in various styles, including crew neck, V-neck, and polo. Made from materials like cotton, polyester, or blends, T-shirts can be plain, printed, or embellished. They are ideal for expressing personal style or promoting brands and events. With endless design possibilities, T-shirts serve as a canvas for creativity, making them a popular choice for custom printing. Whether for daily wear, sports, or special occasions, T-shirts offer a blend of functionality and fashion, catering to diverse preferences and needs.</p>
	</div></div>
      </div>
    <div class="row">
        <div class="col-lg-12 col-md-12">
          <div class="main_heading">
              <h2>Round Neck t-shirts</h2>	
          </div>
        </div>
      <Col lg="3" md="3" sm="4" xs="6" className='main_box'>
            <div>
                <div className='image_wraper img-hover-zoom--slowmo border'>
                  <div> 
                    <img src='http://printingbrother.com/images/int-cat-images/cotton_pb.jpg' alt=''/>
                  </div>
                </div>
                <div className='caption'>
                  <h5>Cotton Classic T-shirts</h5>
                  {/* <p>{discription}</p> */}
                  {/* <a class="" href={link}>View All</a> */}
                </div>
            </div>   
          </Col>
          <Col lg="3" md="3" sm="4" xs="6" className='main_box'>
            <div>
                  <div className='image_wraper img-hover-zoom--slowmo border'>
                    <div> 
                      <img src='http://printingbrother.com/images/int-cat-images/polyster_pb.jpg' alt=''/>
                    </div>
                  </div>
                  <div className='caption'>
                    <h5>Polyester T-Shirt</h5>
                    {/* <p>{discription}</p> */}
                    {/* <a class="" href={link}>View All</a> */}
                  </div>
            </div>       
          </Col>
          <Col lg="3" md="3" sm="4" xs="6" className='main_box'>
            <div>
                  <div className='image_wraper img-hover-zoom--slowmo border'>
                    <div> 
                      <img src='http://printingbrother.com/images/int-cat-images/dryfit_pb.jpg' alt=''/>
                    </div>
                  </div>
                  <div className='caption'>
                    <h5>Sarina/Dri-Fit T-shirts</h5>
                    {/* <p>{discription}</p> */}
                    {/* <a class="" href={link}>View All</a> */}
                  </div>
              </div>       
          </Col>
          <Col lg="3" md="3" sm="4" xs="6" className='main_box'>
            <div>
                  <div className='image_wraper img-hover-zoom--slowmo border'>
                    <div> 
                      <img src='http://printingbrother.com/images/int-cat-images/oversized_pb.jpg' alt=''/>
                    </div>
                  </div>
                  <div className='caption'>
                    <h5>Cotton Oversized T-Shirt</h5>
                    {/* <p>{discription}</p> */}
                    {/* <a class="" href={link}>View All</a> */}
                  </div>
              </div>       
          </Col>
    </div>
    </div>
    </>
  )
}

export default Drinkware