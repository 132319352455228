import React from 'react'
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';


const Menucard  = ({ menuData }) => {
    return (
        <>
        {menuData.map((curElem) => {
          const { id, url, alt, title} = curElem;
            return(
                <>
                    <Col lg="3" md="3" sm="4" xs="4"  id={id}>
                    <Link  to={curElem.url} className='main_box card'>
                            <div className='image_wraper img-hover-zoom--slowmo'>
                                <div key={curElem.id}>
                                    <img src={curElem.image} alt={alt}/>
                                </div>
                            </div>
                            <div className='caption'>
                                <h5>{title}</h5>
                                    {/* <p>{discription}</p> */}
                                    {/* <a class="" href={link}>View All</a> */}
                            </div>
                        </Link>        
                    </Col>
                </>
            )
        })}    
        </>
      )
    } 

export default Menucard