import React from 'react'
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';


const Papercard  = ({ pmenuData }) => {
    return (
        <>
        {pmenuData.map((curElem) => {
          const { id, url, alt, title} = curElem;
            return(
                <>
                    <Col xl="2" lg="2" md="2" sm="3" xs="3"  id={id} className=''>
                    <Link to={url} className='main_box'>
                            <div className='image_wraper img-hover-zoom--slowmo border'>
                                <div key={curElem.id}>
                                    <img src={curElem.image} alt={alt}/>
                                </div>
                            </div>
                            <div className='caption'>
                                <h6>{title}</h6>
                            </div>
                        </Link>        
                    </Col>
                </>
            )
        })}
        </>
      )
    } 

export default Papercard