import React, { useState } from 'react'
import LanyardApi from '../json/lanyardApi';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { Lanyard } from './allinternal';

const Lanyards = () => {

const [lanyardApiData, setLanyardApiData] = useState(LanyardApi);
    return (
      <>
       <Container className='main-div'>
            <Row className="">
              <div class="col-lg-12">
                <div className='Paper-products'>
                <div className='row'>
       <div class="col-lg-12 col-md-12 mb-4">
 		<div className='bg-light p-4'>
 			<div class="main_heading">
 			    <h1>Lanyards & Badges</h1>	
 			</div>
             <p className=''>Discover the perfect custom lanyards at Printing Brother for your corporate events and promotions. Our high-quality lanyards are ideal for conferences, trade shows, and employee identification. Personalize with your logo, text, or design to enhance brand visibility and professionalism. Choose from a variety of materials, colors, and attachments to suit your needs. Printing Brother ensures top-notch craftsmanship and quick turnaround times. Make a lasting impression with our stylish and durable lanyards. Order now for unbeatable service and quality.</p>   
 	</div></div>
       </div>
                <div class="row">
                  <Lanyard lanyardApiData={lanyardApiData} />
                </div></div>
              </div>
            </Row>
        </Container>
      </>
    )
  }

export default Lanyards