import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
// import ExampleCarouselImage from 'images/ExampleCarouselImage';
// import image_1 from '../images/image_1.jpg'
// import image_2 from '../images/image_2.jpg'

const Slider = () => {
  return (
    <Carousel>
      <Carousel.Item>
      <a href="#home" className=''><img src="http://printingbrother.com/images/image_1.jpg"  alt="img"/></a>
        {/* <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption> */}
      </Carousel.Item>
     
      <Carousel.Item>
      <a href="#home" className=''><img src="http://printingbrother.com/images/image_2.jpg"  alt="img"/></a>
      </Carousel.Item>
    </Carousel>
  )
}

export default Slider